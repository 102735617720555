<!--
 * @Description: 新增客户属性
 * @Author: ChenXueLin
 * @Date: 2021-10-21 16:51:13
 * @LastEditTime: 2022-04-21 17:07:44
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 属性基础信息 start -->
          <div class="baseInfo-box">
            <div class="edit-title">属性基础信息</div>
            <el-form
              ref="form"
              :model="form"
              label-width="140px"
              :inline="true"
              :rules="rules"
            >
              <el-form-item class="mt18px" label="属性名称：" prop="attrName">
                <!-- <el-input
                  placeholder="属性名称"
                  v-model="form.attrName"
                ></el-input> -->
                {{ form.attrName }}
              </el-form-item>
            </el-form>
          </div>
          <!-- 属性基础信息 end -->

          <!-- 属性值  start -->
          <div class="baseInfo-box">
            <div class="edit-title">属性值</div>
            <!-- 搜索头部 start -->
            <table-title @resize="handleTableTitleResize">
              <template v-slot:operateAction>
                <i
                  class="e6-icon-add_line"
                  @click="openAddAttrDialog"
                  title="新增"
                ></i>
                <i
                  class="e6-icon-clear_line"
                  @click="deleteHandle"
                  title="删除"
                ></i>
              </template>
              <template v-slot:searchForm>
                <el-form
                  class="search-list"
                  ref="searchForm"
                  :model="searchForm"
                >
                  <el-form-item prop="attrValueDesc">
                    <el-input
                      v-model="searchForm.attrValueDesc"
                      placeholder="属性描述"
                      title="属性描述"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="remark">
                    <el-input
                      v-model="searchForm.remark"
                      placeholder="备注"
                      title="备注"
                      maxlength="100"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="isEnable">
                    <e6-vr-select
                      v-model="searchForm.isEnable"
                      :data="[
                        { id: 0, label: '不可用' },
                        { id: 1, label: '可用' }
                      ]"
                      placeholder="是否可用"
                      title="是否可用"
                      clearable
                    ></e6-vr-select>
                  </el-form-item>
                  <el-form-item class="search-item--buttons">
                    <el-button type="primary" @click="handleSearch">
                      查询
                    </el-button>
                    <el-button class="reset" @click="handleReset">
                      重置
                    </el-button>
                  </el-form-item>
                </el-form>
              </template>
            </table-title>
            <!-- 搜索头部 end -->
            <!-- 表格 start -->
            <section class="table-wrapper">
              <el-table
                ref="tableList"
                border
                :height="`${resizeViewHeight - 200}px`"
                :data="tableData"
                highlight-current-row
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="40"></el-table-column>
                <!-- 序号 start -->
                <el-table-column
                  label="序号"
                  width="50"
                  fixed="left"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>
                      {{ scope.$index + 1 }}
                    </span>
                  </template>
                </el-table-column>
                <!-- 序号 end -->
                <el-table-column
                  label="属性ID"
                  prop="id"
                  :min-width="160"
                  header-align="center"
                  align="center"
                ></el-table-column>
                <el-table-column
                  label="属性描述"
                  prop="attrValueDesc"
                  :min-width="160"
                  header-align="center"
                  align="center"
                ></el-table-column>
                <el-table-column
                  label="备注"
                  prop="remark"
                  :min-width="160"
                  header-align="center"
                  align="center"
                ></el-table-column>
                <el-table-column
                  label="是否可用"
                  prop="isEnable"
                  :min-width="160"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.isEnable"
                      :active-value="1"
                      :inactive-value="0"
                      active-color="#6bccee"
                      @change="val => statusChangeHandle(val, scope.row)"
                      inactive-color="#dae1eb"
                    >
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  label="创建时间"
                  prop="createdTime"
                  :min-width="160"
                  header-align="center"
                  align="center"
                ></el-table-column>
                <el-table-column
                  fixed="right"
                  align="center"
                  label="操作"
                  width="120"
                >
                  <template slot-scope="scope">
                    <e6-td-operate
                      :data="getOperateList()"
                      @command="toEdit($event, scope.row)"
                    ></e6-td-operate>
                  </template>
                </el-table-column>
              </el-table>
            </section>
            <!-- 表格 end -->
            <!-- 分页 start -->
            <section class="pagination-wrapper fixed-section">
              <el-pagination
                :page-size.sync="searchForm.pageSize"
                :current-page.sync="searchForm.pageIndex"
                :page-sizes="pageSizes"
                :layout="layout"
                :total="total"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </section>
            <!-- 分页 end -->
          </div>
        </div>
      </section>
    </div>
    <!-- 子表格弹窗：属性值新增 -->
    <el-dialog
      v-dialogDrag
      title="新增属性值"
      :visible.sync="addAttrValueVisible"
      @open="openDialog"
      width="620px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      custom-class="edit-dialog"
    >
      <el-form
        ref="addAttrValueForm"
        label-width="100px"
        :model="addAttrValueForm"
        :rules="rules"
      >
        <el-form-item label="属性表述" prop="attrValueDesc">
          <el-input
            v-model.trim="addAttrValueForm.attrValueDesc"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model.trim="addAttrValueForm.remark"
            clearable
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否可用" prop="isEnable">
          <el-switch
            v-model="addAttrValueForm.isEnable"
            :active-value="1"
            :inactive-value="0"
            active-color="#6bccee"
            inactive-color="#dae1eb"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirmAddSonAttr">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import {
  createCorpAttr,
  getCorpAttrDetail,
  getCorpAttrList,
  batchDeleteCorpAttr,
  updateCorpAttr
} from "@/api";
export default {
  name: "addCustomerAttr",
  components: {},
  data() {
    return {
      form: {
        attrName: ""
      },
      searchForm: {
        attrValueDesc: "", // 属性描述
        remark: "", // 备注
        isEnable: "", // 是否可用
        pageSize: 20,
        pageIndex: 1
      },
      total: 0,
      selColumnId: [], // 选中的数据
      tableData: [], // 表格数据
      addAttrValueVisible: false,
      queryListAPI: getCorpAttrList,
      addAttrValueForm: {
        attrValueDesc: "", //属性描述
        remark: "", //备注
        isEnable: 1 //是否可用
      },
      rules: {
        attrName: [
          {
            required: true,
            message: "请输入属性名称",
            trigger: ["blur", "change"]
          }
        ],
        attrValueDesc: [
          {
            required: true,
            message: "请输入属性描述",
            trigger: ["blur", "change"]
          }
        ],
        isEnable: [
          {
            required: true,
            message: "请选中是否可用",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  created() {
    let hasParamsRoute = this.getHasParamsRoute();
    this.form.attrName = hasParamsRoute.params.attrName;
    if (this.form.attrName) this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 提交保存 注意虽然页面上显示有字表，但是保存只是一次性保存(包括字表的数据)
    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.createCorpAttr();
        }
      });
    },
    // 新增子属性
    confirmAddSonAttr() {
      this.$refs.addAttrValueForm.validate(valid => {
        if (valid) {
          this.createCorpAttr();
        }
      });
    },
    // 点击属性开关的handle
    // statusChangeHandle(val, row) {
    //   console.log(val, row);
    // },
    //新增属性（请求接口，保存数据）
    async statusChangeHandle(val, row) {
      try {
        this.loading = true;
        let res = await updateCorpAttr(row);
        if (res.code == "OK") {
          this.$message.success("修改属性成功");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.queryList();
        this.loading = false;
      }
    },
    //新增属性（请求接口，保存数据）
    async createCorpAttr() {
      try {
        this.loading = true;
        this.form.attrValue = this.tableData;
        let handleApi = createCorpAttr;
        let msg = "新增属性成功";
        if (this.addAttrValueForm.id) {
          handleApi = updateCorpAttr;
          msg = "修改属性成功";
        }
        let res = await handleApi(this.addAttrValueForm);
        if (res.code == "OK") {
          this.$message.success(msg);
          this.closeDialog();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取属性详情
    async getCorpAttrDetail(id) {
      console.log(id);
      try {
        this.loading = true;
        let res = await getCorpAttrDetail({
          attrName: "客户属性"
        });
        if (res.code == "OK") {
          this.form = this.getFreezeResponse(res, {
            path: "data"
          });
          this.tableData = this.form.attrValue;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 表格多选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.id;
      });
    },
    // 打开新增属性弹窗
    openAddAttrDialog() {
      this.addAttrValueVisible = true;
    },
    // 打开编辑弹窗
    toEdit(val, row) {
      this.openAddAttrDialog();
      this.addAttrValueForm = {
        ...row
      };
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    //删除询问
    deleteHandle() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择要操作的数据");
        return;
      } else {
        this.$confirm("是否确认删除所选属性?", "删除属性", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.deleteAttr();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
      }
    },
    async deleteAttr() {
      try {
        this.loading = true;
        let res = await batchDeleteCorpAttr({ ids: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    openDialog() {},
    closeDialog() {
      this.addAttrValueVisible = false;
      this.addAttrValueForm = {
        attrValueDesc: "", //属性描述
        remark: "", //备注
        isEnable: 1 //是否可用
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.baseInfo-box {
  width: 100%;
  .el-form {
    .el-input {
      width: 220px;
    }
    .e6-vr-select {
      width: 220px;
    }
  }
}
/deep/ .edit-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
.mt18px {
  margin-top: 18px;
}
</style>
